<template>
    <div class="flex row no-wrap w-100">
        <b-nav vertical tabs v-if="isEdit" class="left-nav-bar">
            <b-nav-item :active="$route.path === '/manageaoi/pipeline/' + $route.params.id || $route.path === '/manageaoi/pipeline/' + $route.params.id + '/'" v-on:click="changePage('')">Scene</b-nav-item>
            <b-nav-item :active="$route.path === '/manageaoi/pipeline/' + $route.params.id + '/settings' || $route.path === '/manageaoi/pipeline/' + $route.params.id + '/settings/'" v-on:click="changePage('settings')">Settings</b-nav-item>
        </b-nav>
        <router-view @onNameChanged="$emit('onNameChanged', $event)"></router-view>
    </div>
</template>

<script>
    export default {
        name: "Pipeline.vue",
        data() {
            return {
                modelObject: null,
                isEdit: false
            }
        },
        beforeMount() {
            this.modelObject = this.$route.params.modelObject;
            if(this.modelObject.scene.name) {
                this.isEdit = true;
            }
        },
        methods: {
            changePage(goTo) {
                const newRoute = `/manageaoi/pipeline/${this.$route.params.id}/${goTo}`;
                if(this.$route.path !== newRoute){
                    this.$router.push(newRoute)
                }
            }
        }
    }
</script>

<style lang="scss">
    .left-nav-bar {
        min-width: 150px;
        border-right: solid 1px #353C45;
        margin-right: -1px;
        border-bottom: none;
        margin-bottom: 25px;

    .nav-item{
            margin-bottom: 0px;
            margin-right: -1px;
            border-right: solid 1px rgb(33, 44, 56);
            .active{
                background-color: inherit!important;
                border-right: none;
            }
            .nav-link{
                color: #CED6E0;
                margin-right: -1px;
                border-radius: 0;
                border-right: 1px solid #353C45;
            }
            .nav-link:hover{
                color: #0A78CC;
                border: none;
                border-right: 1px solid #353C45;
                margin-right: -1px;
            }
            .nav-link.active {
                color: #CED6E0;
                border-top: solid 1px #353C45;
                border-bottom: solid 1px #353C45;
                border-left: solid 3px #0A78CC;
                border-right: none;
                background-color: inherit!important;
            }
        }

    }
    .aoi-form-wrapper{
        width: 100%;
        margin: 0 25px;
        display: flex;
        flex-direction: column;
        height: 100%;
        .validation-error {
            border-color: #FF526F!important;
            border-width: 2px!important;
        }
        .aoi-label {
            color: #cfe2f2;
            font-size: 12px;
            margin-bottom: 0;
            margin-left: 5px;
        }
        .aoi-icon {
            cursor: pointer;
            color: #cfe2f2;
            font-size: 20px;
        }
        .aoi-field-wrapper {
            min-width: 240px;
            .aoi-field {
                padding: 4px 4px 4px 5px;
                font-size: 12px;
                font-weight: 400;
                color: white;
                background-color: #fff;
                border: 0;
                margin-top: 8px;
            }
            input:disabled{
                border: none;
            }
            .aoi-field-gray-bg {
                background-color: rgb(33, 44, 56);
                border: 1px solid #4B5561;
            }
            .aoi-field-gray-bg:focus {
                box-shadow: 0 0 0 0.2rem rgba(13, 150, 255, 1);
                border: none;
            }
        }
        input:disabled {
            font-weight: bold;
            font-size: 14px;
            border: none;
        }
    }
</style>
